// @ts-strict-ignore
import React, { type FC, type ReactElement } from 'react';
import { type Image } from 'bb/common/images';
import { Text } from 'bb/i18n';
import { getContrastColor } from 'bb/style/utils';
import { Box, type BoxBaseProps, Gap, type BoxProps } from 'bb/ui';
import { MaxWidthContainer } from '../Containers';

export type HeroProps = {
    title: string;
    subTitle?: ReactElement | string;
    backgroundImage?: ReactElement<typeof Image>;
} & Omit<BoxProps, Exclude<keyof BoxBaseProps, 'bgColor'>>;

export const Hero: FC<HeroProps> = ({
    title,
    subTitle,
    bgColor,
    children,
    ...restProps
}) => {
    const textColor = bgColor ? getContrastColor(bgColor) : 'primary-white';

    return (
        <Box padding={[4, 4]} bgColor={bgColor} {...restProps}>
            <MaxWidthContainer maxWidth={800}>
                <Text
                    data-optimizely="hero-title"
                    center
                    as="h1"
                    variant="h2Primary"
                    color={textColor}
                >
                    {title}
                </Text>
                <Gap justifyContent="center" alignItems="center" spacing={2}>
                    <Text color={textColor} as="div" variant="body1" center>
                        {subTitle}
                    </Text>
                    {children}
                </Gap>
            </MaxWidthContainer>
        </Box>
    );
};
